import { useContext, useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faChevronLeft, faTimes, faUser, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { createMeetingRoomBook, getMeetingRoomsFreeRanges } from '../api/meet-rooms'
import { getAllUsers } from '../api/users'
import { dayNames } from '../utils/CalendarDataTime'
import { AppContext } from '../context'
import Input from './Input'
import Steps from './Steps'
import Loader from './Loader'
import ErrorModal from './modals/ErrorModal'
import FinishModal from './modals/FinishModal'
import chroma from 'chroma-js'
import { captureException } from '@sentry/react'


const RoomsAdminReservationModal = ({ days, hours, refreshData, initialData, changeMonth, closeModal, serverTime }) => {
  const { token, updateUser, rooms } = useContext(AppContext)
  const [ step, setStep ] = useState(1)
  const [ loading, setLoading ] = useState(true)
  // admin-user, admin-vida, admin-no-user
  const [ reservation, setReservation ] = useState({
    room_id: initialData.room_id,
    room_name: initialData.room_name,
    user_type: '',
    start_date: '',
    start_hour: initialData.start_hour,
    end_date: '',
    end_hour: initialData.end_hour,
    user_id: '',
    first_name: '',
    last_name: '',
    reference: ''
  })
  const [ reservationError, setReservationError ] = useState()
  const [ dateState, setDateState ] = useState({
    day: initialData.dayWeekName,
    dayNumber: initialData.dayNumber,
    month: initialData.month,
    year: initialData.year
  })
  const [ currentDay, setCurrentDay ] = useState([])
  const [ roomsWithSchedule, setRoomsWithSchedule ] = useState([])
  const [ limitHours, setLimitHours ] = useState({
    startHours: [],
    endHours: []
  })
  const [ totalHours, setTotalHours ] = useState(0)
  const [ users, setUsers ] = useState([])

  const roomsContainerRef = useRef(null)
  const roomRef = useRef(null)

  const modalDate = `${dateState.day} ${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber}/${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}`
  const requestDate = `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber}`

  const userTypes = [
    {
      text: 'Usuario no registrado',
      value: 'admin-no-user'
    }, {
      text: 'Usuario registrado',
      value: 'admin-user'
    }, {
      text: 'Personal de Vida Cowork u otro',
      value: 'admin-vida'
    }
  ]


  // Get data and hours available at date
  useEffect(() => {
    const getData = async () => {
      // Find user reservations for the current day
      const currentDayData = days.find(day => {
        if(day.dayWeekName == dateState.day && day.dayNumber == dateState.dayNumber) {
          // const existUserReservation = day.data.find(reservation => reservation.is_owner)
          return day
        }
      })

      if(currentDayData) {
        setCurrentDay(currentDayData == undefined ? currentDay : currentDayData)

        // Set rooms schedule
        if(!currentDayData.isPastDay) {
          const response = await getMeetingRoomsFreeRanges(token, {
            date: requestDate
          })

          if(response) {
            const newRoomsData = rooms.map(room => {
              const date = `${requestDate}T00:00:00`

              const scheduleHours = response[room.id]?.map(range => [range.start_time, range.end_time])
              const scheduleTimes = response[room.id]?.map(range => {
                const startDate = new Date(date)
                startDate.setHours(range.start_time.split(':')[0], range.start_time.split(':')[1], 0, 0)
                const startTime = startDate.getTime()

                const endDate = new Date(date)
                endDate.setHours(range.end_time.split(':')[0], range.end_time.split(':')[1], 0, 0)
                const endTime = endDate.getTime()

                return [startTime, endTime]
              })

              return ({
                ...room,
                scheduleHours,
                scheduleTimes
              })
            })
            setRoomsWithSchedule(newRoomsData)
          }
        }

        setLoading(false)
      }
    }

    getData()
  }, [dateState])

  // Set start hours
  useEffect(() => {
    const currentRoom = roomsWithSchedule.find(item => item.id == reservation.room_id)
    const scheduleTimes = currentRoom?.scheduleTimes || []
    const startHours = []

    const date = new Date(serverTime)
    const currentTime = date.getTime()
    const timeLimit = 1200000 // 20 minutes

    if(scheduleTimes) {
      for (let index = 0; index < hours.length; index++) {
        const hour = hours[index]
        const date = new Date(reservation.start_date)
        date.setHours(hour.split(':')[0], hour.split(':')[1], 0, 0)
        const hourTime = date.getTime()

        let hoursIsAvailable = false

        for (let index = 0; index < scheduleTimes.length; index++) {
          const startLimit = scheduleTimes[index][0]
          const endLimit = scheduleTimes[index][1]


          if(hourTime > (currentTime - timeLimit) && hourTime >= startLimit && hourTime < endLimit) {
            hoursIsAvailable = true
          }
        }

        if(hoursIsAvailable) {
          startHours.push(hours[index])
        }
      }

      setLimitHours({
        startHours,
        endHours: []
      })
    }

  }, [reservation.room_id, roomsWithSchedule, dateState])

  // Set end hours
  useEffect(() => {
    const currentRoom = roomsWithSchedule.find(item => item.id == reservation.room_id)
    const scheduleTimes = currentRoom?.scheduleTimes || []

    if(reservation.start_hour && limitHours.startHours.length > 0) {
      const endHours = []

      const startTime = new Date(reservation.start_date).getTime()

      // Get reservation free range for the selected start hour
      const reservationFreeRange = scheduleTimes.find((item) => {
        const startLimit = item[0]
        const endLimit = item[1]

        if(startLimit >= startLimit && startTime <= endLimit) {
          return item
        }
      })

      // Set end hours by reservation free range
      for (let index = 0; index < hours.length; index++) {
        const hour = hours[index]
        const date = new Date(reservation.start_date)
        date.setHours(hour.split(':')[0], hour.split(':')[1], 0, 0)
        const hourTime = date.getTime()

        let hoursIsAvailable = false

        const startLimit = reservationFreeRange[0]
        const endLimit = reservationFreeRange[1]
        const startReservationTime = date.getTime()

        // check if hour is available
        if(startReservationTime > startTime && hourTime > startLimit && hourTime <= endLimit) {
          hoursIsAvailable = true
        }

        if(hoursIsAvailable) {
          endHours.push(hours[index])
        }
      }

      setLimitHours({
        startHours: limitHours.startHours,
        endHours
      })
    }
  }, [reservation.start_hour, limitHours.startHours])

  // Reset hours when change room
  useEffect(() => {
    setReservation({
      ...reservation,
      start_hour: '',
      end_hour: ''
    })
  }, [reservation.room_id])

  // Check hours difference and set limit hours
  useEffect(() => {
    const checkHoursAndSetLimit = async () => {
      const start = new Date(reservation.start_date).getTime()
      const end = new Date(reservation.end_date).getTime()
      const diff = (end - start) / 1000 / 60 / 60

      setTotalHours(diff)
    }

    checkHoursAndSetLimit()
  }, [reservation.end_date])

  // Scroll to selected room
  useEffect(() => {
    const roomsContainer = roomsContainerRef.current
    const room = roomRef.current

    if(roomsContainer && room && step == 2) {
      const optionOffsetTop = room.offsetTop
      const containerOffsetTop = roomsContainer.offsetTop
      const offsetTop = optionOffsetTop - containerOffsetTop

      roomsContainer.scroll(0, offsetTop)
    }
  }, [roomsContainerRef.current, roomRef.current, step])

  // Set initial date
  useEffect(() => {
    setReservation({
      ...reservation,
      // room_id: initialData.room_id,
      start_date: `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber} ${initialData.start_hour || '00'}:00`,
      start_hour: initialData.start_hour,
      end_date: `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber} ${initialData.end_hour}:00`,
      end_hour: initialData.end_hour
    })
  }, [initialData])

  // Get users
  useEffect(() => {
    const getData = async () => {
      const response = await getAllUsers(token)

      setUsers(response)
    }

    getData()
  }, [])


  const changeDate = (direction) => {
    const daysInMonth = new Date(dateState.year, dateState.month + 1, 0).getDate()

    let day
    let month
    let year
    let dayName

    if(direction == 'next') {
      let step = 1
      if(dateState.day == 'Viernes') {
        step = 3
      }

      day = dateState.dayNumber + step
      month = dateState.month
      year = dateState.year

      if(day > daysInMonth) {
        day = day - daysInMonth
        month = month + 1
        changeMonth(direction)
      }

      if(month > 11) {
        month = 0
        year = year + 1
      }

      const date = new Date(year, month, day)
      dayName = date.toString().slice(0, 3)

      setDateState({
        day: dayNames[dayName],
        dayNumber: day,
        month: month,
        year: year
      })
    } else {
      let step = 1
      if(dateState.day == 'Lunes') {
        step = 3
      }

      day = dateState.dayNumber - step
      month = dateState.month
      year = dateState.year

      if(day < 1 && dateState.day == 'Lunes') {
        day = new Date(year, month, day).getDate()
        month = month - 1
        changeMonth(direction)
      } else if(day < 1) {
        day = new Date(year, month, 0).getDate()
        month = month - 1
        changeMonth(direction)
      }

      if(month < 0) {
        month = 11
        year = year - 1
      }

      const date = new Date(year, month, day)
      dayName = date.toString().slice(0, 3)

      // Reset reservation hours when change date
      setDateState({
        day: dayNames[dayName],
        dayNumber: day,
        month: month,
        year: year
      })
    }

    setReservation({
      ...reservation,
      start_date: `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${day < 10 ? `0${day}` : day} 00:00`,
      start_hour: '',
      end_date: `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${day < 10 ? `0${day}` : day} 00:00`,
      end_hour: ''
    })
  }

  const handleUserType = (value) => {
    setReservation({
      ...reservation,
      user_type: value,
      reference: '',
      user_id: '',
      first_name: '',
      last_name: ''
    })
  }

  const handleUserSelector = (id) => {
    setReservation({
      ...reservation,
      user_id: id,
      first_name: users.find(item => item.id == id)?.first_name,
      last_name: users.find(item => item.id == id)?.last_name
    })
  }

  const handleTextInput = (e) => {
    switch (e.target.name) {
    case 'first_name':
      setReservation({
        ...reservation,
        first_name: e.target.value
      })
      break

    case 'last_name':
      setReservation({
        ...reservation,
        last_name: e.target.value
      })
      break

    case 'reference':
      setReservation({
        ...reservation,
        reference: e.target.value
      })
      break

    case 'description':
      setReservation({
        ...reservation,
        description: e.target.value
      })
      break

    default:
      break
    }
  }

  const handleRoomOption = (value) => {
    const room_name = roomsWithSchedule.find(item => item.id == value)?.name

    setReservation({
      ...reservation,
      room_id: value,
      room_name: room_name
    })
  }

  const handleHourStart = (name, start) => {
    setReservation({
      ...reservation,
      start_date: `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber} ${start}:00`,
      start_hour: start,
      end_date: '',
      end_hour: '',
    })
  }

  const handleHourEnd = (name, end) => {
    setReservation({
      ...reservation,
      end_date: `${dateState.year}-${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}-${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber} ${end}:00`,
      end_hour: end
    })
  }

  const createReservation = async (e) => {
    e.target.disabled = true

    const data = await createMeetingRoomBook(token, {
      room_id: reservation.room_id,
      user_id: reservation.user_id,
      user_type: reservation.user_type,
      start_date: reservation.start_date,
      end_date: reservation.end_date,
      first_name: reservation.first_name,
      last_name: reservation.last_name,
      reference: reservation.reference
    })

    if(!data.error) {
      setStep(step + 1)
      updateUser()
      refreshData()
    } else {
      setStep(5)
      if(data.error == 'You don\'t have enough hours to use them for that date') {
        setReservationError('El usuario no tiene horas suficientes para reservar en esta fecha')
      } else {
        captureException(new Error(data), { tags: { section: 'RoomsAdminReservationModal' } })
        setReservationError('Por favor anota los datos de la reserva y contactate con el equipo de linkchar para que podamos solucionar el problema')
      }
    }
  }


  if(loading) return (
    <div className="PanelModal px-3">
      <div className="PanelModal__Card">
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva tu sala de reunión</h2>
        </header>

        <div className='PanelModal__Date-Selector PanelModal__Confirm'>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('prev')}>
            <FontAwesomeIcon icon={faCaretLeft}/>
          </button>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('next')}>
            <FontAwesomeIcon icon={faCaretRight}/>
          </button>
        </div>

        <div className="title text-center py-5">
          <Loader/>
        </div>
      </div>
    </div>
  )

  if(step === 1) return (
    <div className="PanelModal px-3">
      <div className="PanelModal__Card">
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva sala de reunión</h2>
        </header>

        <div className='PanelModal__Date-Selector PanelModal__Confirm'>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('prev')}>
            <FontAwesomeIcon icon={faCaretLeft}/>
          </button>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('next')}>
            <FontAwesomeIcon icon={faCaretRight}/>
          </button>
        </div>

        {currentDay?.isPastDay ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">No podés reservar una sala de reunión para una fecha en el pasado</span>
          </div>
        ) : currentDay?.isDisabled ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">Esta fecha está inhabilitada para realizar reservas</span>
          </div>
        ) : (
          <>
            <div className="mt-3">
              <h3 className='text-4 text-start'>Reserva para:</h3>

              <div className="PanelModal__Options-Container PanelModal__Confirm">
                {userTypes.map((option, index) => {
                  if(option.value === reservation.user_type) return (
                    <button
                      className="PanelModal__Option--Active justify-content-start"
                      key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                    >
                      <div className="PanelModal__Option-Circle--Active"></div>
                      <span className='text-4'>
                        {option.text}
                      </span>
                    </button>
                  )
                  else return (
                    <button
                      className="PanelModal__Option justify-content-start"
                      key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                      onClick={() => handleUserType(option.value)}
                    >
                      <div className="PanelModal__Option-Circle"></div>
                      <span className='text-4'>
                        {option.text}
                      </span>
                    </button>
                  )
                })}
              </div>

              {reservation.user_type && (
                <>
                  {reservation.user_type === 'admin-no-user' && (
                    <>
                      <div className="PanelModal__Inputs-Container mt-3">
                        <Input
                          label='Nombre del usuario'
                          name='first_name'
                          value={reservation.first_name}
                          onChange={handleTextInput}
                          icon={faUser}
                        />
                        <Input
                          label='Apellido del usuario'
                          name='last_name'
                          value={reservation.last_name}
                          onChange={handleTextInput}
                          icon={faUser}
                        />
                      </div>
                      <div className="mt-3">
                        <Input
                          label='Descripción'
                          name='description'
                          value={reservation.description}
                          onChange={handleTextInput}
                        />
                      </div>
                    </>
                  )}

                  {reservation.user_type === 'admin-user' && (
                    <>
                      <div className="mt-3 mb-5">
                        <Input
                          label='Nombre del usuario'
                          name='user'
                          search={users}
                          value={reservation.first_name}
                          onChange={handleUserSelector}
                          icon={faUser}
                        />
                      </div>
                      <div className="mt-3">
                        <Input
                          label='Descripción'
                          name='description'
                          value={reservation.description}
                          onChange={handleTextInput}
                        />
                      </div>
                    </>
                  )}

                  {reservation.user_type === 'admin-vida' && (
                    <>
                      <div className="mt-3">
                        <Input
                          label='Referencia para la reserva especial'
                          name='reference'
                          placeholder='Ej: evento, reunión, etc'
                          value={reservation.reference}
                          onChange={handleTextInput}
                          icon={faUser}
                        />
                      </div>
                      <div className="mt-3">
                        <Input
                          label='Descripción'
                          name='description'
                          value={reservation.description}
                          onChange={handleTextInput}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="d-flex justify-content-center">
              {reservation.user_type === 'admin-no-user' && (
                <>
                  {reservation.first_name && reservation.last_name
                    ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
                    : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                  }
                </>
              )}

              {reservation.user_type === 'admin-user' && (
                <>
                  {reservation.user_id
                    ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
                    : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                  }
                </>
              )}

              {reservation.user_type === 'admin-vida' && (
                <>
                  {reservation.reference
                    ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
                    : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                  }
                </>
              )}

              {reservation.user_type === '' && (
                <button className='button-disabled-panel my-3' disabled>Siguiente</button>
              )}
            </div>
          </>
        )}

        <div className="mt-3">
          <Steps totalCount={3} currentCount={1} />
        </div>
      </div>
    </div>
  )

  if(step === 2) return (
    <div className="PanelModal px-3">
      <div className="PanelModal__Card">
        <header>
          <button className='PanelModal__Button-GoBack' onClick={() => setStep(step - 1)}>
            <FontAwesomeIcon icon={faChevronLeft}/>
          </button>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva sala de reunión</h2>
        </header>

        <div className="PanelModal__Scroll-Content--Small" ref={roomsContainerRef}>
          <div className="RoomsReservationModal__Options-Container">
            {roomsWithSchedule.length > 0
              ? roomsWithSchedule.map((room, index) => {
                if (room?.scheduleHours?.length == 0) return (
                  <button
                    key={`Spaces-Reservation-Modal-Option--Disabled-${room.name}-${index}`}
                    className='RoomsReservationModal__Option--Disabled'
                  >
                    <div className="RoomsReservationModal__Option-Xmark">
                      <FontAwesomeIcon icon={faTimes}/>
                    </div>
                    <span className='text-4'>
                    Sala {room.name} - Capacidad: {room.capacity}<br/>
                      <div className='text-5 d-flex flex-column align-items-start'>
                      No hay horas disponibles
                      </div>
                    </span>
                  </button>
                )

                if(room.id === reservation.room_id) return (
                  <button
                    key={`Spaces-Reservation-Modal-Option--Active-${room.name}-${index}`}
                    className='RoomsReservationModal__Option--Active'
                    style={{
                      backgroundColor: room.color,
                      borderLeftColor: chroma(room.color).darken(1).hex(),
                    }}
                    ref={roomRef}
                  >
                    <div className="RoomsReservationModal__Option-Circle--Active"></div>
                    <span className='text-4'>
                    Sala {room.name} - Capacidad: {room.capacity}<br/>
                      <div className='text-5 d-flex flex-column align-items-start'>
                      Libre de:<br/>
                        {room?.scheduleHours?.map((time, index) => (
                          <span key={`Rooms-Reservation-Modal-Option-Active-${index}`}>{time[0]} - {time[1]}hs</span>
                        ))}
                      </div>
                    </span>
                  </button>
                )

                return (
                  <button
                    key={`Spaces-Reservation-Modal-Option-${room.name}-${index}`}
                    className='RoomsReservationModal__Option'
                    style={{
                      backgroundColor: chroma(room.color).luminance(0.8).hex()
                    }}
                    onClick={() => handleRoomOption(room.id)}
                  >
                    <div className="RoomsReservationModal__Option-Circle"></div>
                    <span className='text-4'>
                    Sala {room.name} - Capacidad: {room.capacity}<br/>
                      <div className='text-5 d-flex flex-column align-items-start'>
                      Libre de:<br/>
                        {room?.scheduleHours?.map((time, index) => (
                          <span key={`Rooms-Reservation-Modal-Option-${index}`}>{time[0]} - {time[1]}hs</span>
                        ))}
                      </div>
                    </span>
                  </button>
                )})
              : <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Loader/>
              </div>
            }
          </div>
        </div>

        <div className="RoomsReservationModal__Inputs-Container mt-2 mb-4">
          <Input
            label='Desde'
            name='hour-start'
            list={limitHours.startHours}
            value={reservation.start_hour}
            onChange={handleHourStart}
            // error={formik.errors.hourStart}
            icon={faClock}
            disabled={!reservation.room_id}
          />
          <Input
            label='Hasta'
            name='hour-end'
            list={limitHours.endHours}
            value={reservation.end_hour}
            onChange={handleHourEnd}
            // error={formik.errors.hourStart}
            icon={faClock}
            disabled={!reservation.start_hour}
          />
        </div>

        <div className="d-flex justify-content-center">
          {reservation.room_id && reservation.start_hour && reservation.end_hour
            ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
            : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
          }
        </div>

        <Steps totalCount={3} currentCount={2} />
      </div>
    </div>
  )

  if(step === 3) return (
    <div className="PanelModal px-3">
      <div className="PanelModal__Card">
        <header>
          <button className='PanelModal__Button-GoBack' onClick={() => setStep(step - 1)}>
            <FontAwesomeIcon icon={faChevronLeft}/>
          </button>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva sala de reunión</h2>
        </header>

        <div className='PanelModal__Confirm'>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
        </div>

        <div className="PanelModal__Confirm">
          {reservation.first_name && (
            <span>Usuario: <span className="fw-bold">{reservation.first_name} {reservation.last_name}</span></span>
          )}
          {reservation.reference && (
            <span>Referencia: <span className="fw-bold">{reservation.reference}</span></span>
          )}
        </div>

        <div className="PanelModal__Confirm">
          <h3 className="mx-2 text-1">{ reservation.room_name }</h3>
          <span className="text-4">
            De <span className="fw-bold">{ reservation.start_hour }</span> a <span className="fw-bold">{ reservation.end_hour }</span>
          </span>
        </div>

        <div className="PanelModal__Confirm">
          <span>Total: <span className="fw-bold">{totalHours == 1 ? `${totalHours} hora` : `${totalHours} horas`}</span></span>
        </div>

        <div className="d-flex justify-content-center">
          <button className='button-green-panel my-3' onClick={createReservation}>Reservar</button>
        </div>

        <Steps totalCount={3} currentCount={3} />
      </div>
    </div>
  )

  if(step === 4) return (
    <FinishModal
      title='Reserva sala de reunión'
      message='¡Reserva exitosa!'
      closeModal={closeModal}
    />
  )

  if(step === 5) return (
    <ErrorModal
      title='Algo salió mal'
      message={reservationError}
      closeModal={closeModal}
      data={reservation}
      type='rooms'
    />
  )
}

RoomsAdminReservationModal.propTypes = {
  days: propTypes.array,
  hours: propTypes.array.isRequired,
  initialData: propTypes.object,
  refreshData: propTypes.func,
  changeMonth: propTypes.func,
  closeModal: propTypes.func.isRequired,
  serverTime: propTypes.string.isRequired
}

export default RoomsAdminReservationModal