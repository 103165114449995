import { useState, useContext, useEffect } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faChevronLeft, faUser, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import ChairsSelector from '../../containers/ChairsSelector'
import FinishModal from './FinishModal'
import ModalHeader from '../ModalHeader'
import Input from '../Input'
import { AppContext } from '../../context/index'
import { createSharedAreaBook } from '../../api/shared-areas'
import { dayNames } from '../../utils/CalendarDataTime'
import DefaultImage from '../../assets/chairs-map.webp'
import Loader from '../Loader'
import Steps from '../Steps'
import ErrorModal from './ErrorModal'
import { getAllUsers } from '../../api/users'
import { captureException } from '@sentry/react'

const SpacesAdminReservationModal = ({ days, initialData, refreshData, changeMonth, closeModal }) => {
  const { token, updateUser, sharedAreasTime } = useContext(AppContext)
  const [ step, setStep ] = useState(1)
  const [ loading, setLoading ] = useState(true)
  const [ reservation, setReservation ] = useState({
    booking_type: '',
    user_type: '',
    chair: '',
    chair_list: [],
    date: `${initialData.year}-${initialData.month + 1 < 10 ? `0${initialData.month + 1}` : initialData.month + 1}-${initialData.dayNumber < 10 ? `0${initialData.dayNumber}` : initialData.dayNumber}`,
    first_name: '',
    last_name: '',
    reference: '',
    description: '',
    is_test_day: false,
  })
  const [ reservationError, setReservationError ] = useState()
  const [ dateState, setDateState ] = useState({
    day: initialData.dayWeekName,
    dayNumber: initialData.dayNumber,
    month: initialData.month,
    year: initialData.year
  })
  const [ currentDay, setCurrentDay ] = useState([])
  const [ availability, setAvailability ] = useState({
    isMorningAvailable: true,
    isAfternoonAvailable: true
  })
  const [ users, setUsers ] = useState([])

  const modalDate = `${dateState.day} ${dateState.dayNumber < 10 ? `0${dateState.dayNumber}` : dateState.dayNumber}/${dateState.month + 1 < 10 ? `0${dateState.month + 1}` : dateState.month + 1}`

  const bookingTypes = [
    {
      value: 'Mañana',
      text: `Por la mañana (${sharedAreasTime.morningStartTime} - ${sharedAreasTime.morningEndTime}hs)`,
      schedule: `${sharedAreasTime.morningStartTime} - ${sharedAreasTime.morningEndTime}hs`,
      credits: '1 crédito'
    }, {
      value: 'Tarde',
      text: `Por la tarde (${sharedAreasTime.afternoonStartTime} - ${sharedAreasTime.afternoonEndTime}hs)`,
      schedule: `${sharedAreasTime.afternoonStartTime} - ${sharedAreasTime.afternoonEndTime}hs`,
      credits: '1 crédito'
    }, {
      value: 'Completo',
      text: `Jornada completa (${sharedAreasTime.morningStartTime} - ${sharedAreasTime.afternoonEndTime}hs)`,
      schedule: `${sharedAreasTime.morningStartTime} - ${sharedAreasTime.afternoonEndTime}hs`,
      credits: '2 créditos'
    }
  ]
  const userTypes = [
    {
      text: 'Usuario no registrado',
      value: 'admin-no-user'
    }, {
      text: 'Usuario registrado',
      value: 'admin-user'
    }, {
      text: 'Personal de Vida Cowork u otro',
      value: 'admin-vida'
    }
  ]
  const testDayOptions = [
    {
      value: true,
      text: 'Sí'
    }, {
      value: false,
      text: 'No'
    }
  ]


  useEffect(() => {
    const getData = async () => {
      // Find user reservations for the current day
      const currentDayData = days.find(day => {
        if(day.dayWeekName == dateState.day && day.dayNumber == dateState.dayNumber) {
          // const existUserReservation = day.data.find(reservation => reservation.is_owner)
          return day
        }
      })

      setCurrentDay(currentDayData == undefined ? currentDay : currentDayData)

      // Find availability for the current day
      const availabilityDayData = days.find(day => {
        if(day.dayWeekName == dateState.day && day.dayNumber == dateState.dayNumber) return day
      })

      setAvailability({
        isMorningAvailable: availabilityDayData?.isMorningAvailable === undefined ? true : availabilityDayData?.isMorningAvailable,
        isAfternoonAvailable: availabilityDayData?.isAfternoonAvailable === undefined ? true : availabilityDayData?.isAfternoonAvailable
      })

      setLoading(false)
    }

    getData()
  }, [days, dateState])

  // Get users
  useEffect(() => {
    const getData = async () => {
      const response = await getAllUsers(token)

      setUsers(response)
    }

    getData()
  }, [])


  const changeDate = (direction) => {
    const daysInMonth = new Date(dateState.year, dateState.month + 1, 0).getDate()
    if(direction == 'next') {
      let step = 1
      if(dateState.day == 'Viernes') {
        step = 3
      }

      let day = dateState.dayNumber + step
      let month = dateState.month
      let year = dateState.year

      if(day > daysInMonth) {
        day = day - daysInMonth
        month = month + 1
        changeMonth(direction)
      }

      if(month > 11) {
        month = 0
        year = year + 1
      }

      const date = new Date(year, month, day)
      const dayName = date.toString().slice(0, 3)

      setDateState({
        day: dayNames[dayName],
        dayNumber: day,
        month: month,
        year: year
      })
    } else {
      let step = 1
      if(dateState.day == 'Lunes') {
        step = 3
      }

      let day = dateState.dayNumber
      let month = dateState.month
      let year = dateState.year

      if(day == 1 && dateState.day == 'Lunes') {
        const daysInPastMonth = new Date(year, month, 0).getDate()
        month = month - 1
        changeMonth(direction)
        day = daysInPastMonth - 2
      } else if((day - step) < 1) {
        const daysInPastMonth = new Date(year, month, 0).getDate()
        month = month - 1
        changeMonth(direction)
        day = daysInPastMonth
      } else {
        day = day - step
      }

      if(month < 0) {
        month = 11
        year = year - 1
      }

      const date = new Date(year, month, day)
      const dayName = date.toString().slice(0, 3)

      setDateState({
        day: dayNames[dayName],
        dayNumber: day,
        month: month,
        year: year
      })
    }
  }

  const handleReservationUserType = (value) => {
    setReservation({
      ...reservation,
      user_type: value
    })
  }

  const handleUserSelector = (id) => {
    setReservation({
      ...reservation,
      user_id: id,
      first_name: users.find(item => item.id == id)?.first_name,
      last_name: users.find(item => item.id == id)?.last_name
    })
  }

  const handleTextInput = (e) => {
    switch (e.target.name) {
    case 'first_name':
      setReservation({
        ...reservation,
        first_name: e.target.value
      })
      break

    case 'last_name':
      setReservation({
        ...reservation,
        last_name: e.target.value
      })
      break

    case 'reference':
      setReservation({
        ...reservation,
        reference: e.target.value
      })
      break

    case 'description':
      setReservation({
        ...reservation,
        description: e.target.value
      })
      break

    default:
      break
    }
  }

  const handleReservationTestDay = (value) => {
    setReservation({
      ...reservation,
      is_test_day: value
    })
  }

  const handleReservationBookingType = (value) => {
    setReservation({
      ...reservation,
      booking_type: value
    })
  }

  const handleReservationChair = (chair) => {
    if(reservation.user_type == 'admin-vida') {
      setReservation({
        ...reservation,
        chair_list: chair
      })
    } else {
      setReservation({
        ...reservation,
        chair: chair
      })
    }
  }

  const createReservation = async (e) => {
    e.target.disabled = true
    let data

    if(reservation.user_type == 'admin-vida') {
      const chairsId = reservation.chair_list.map(chair => chair.id).join(',')

      data = await createSharedAreaBook(token, {
        ...reservation,
        chair_list: chairsId,
      })
    } else {
      data = await createSharedAreaBook(token, {
        ...reservation,
        chair: reservation.chair.id,
      })
    }

    if(!data.error) {
      setStep(step + 1)
      updateUser()
      refreshData()
    } else {
      setStep(5)
      if(data.error == 'You don\'t have enough credits to use them for that date') {
        setReservationError('El usuario no tiene créditos suficientes para reservar en esta fecha')
      } else {
        captureException(new Error(data), { tags: { section: 'SpacesAdminReservationModal' } })
        setReservationError('Por favor anota los datos de la reserva y contactate con el equipo de linkchar para que podamos solucionar el problema')
      }
    }
  }


  if(loading) return (
    <div className="PanelModal px-3">
      <div className="PanelModal__Card">
        <header>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reservar espacio compartido</h2>
        </header>

        <div className='PanelModal__Date-Selector PanelModal__Confirm'>
          <button className='PanelModal__Button-Arrow text-1'>
            <FontAwesomeIcon icon={faCaretLeft}/>
          </button>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
          <button className='PanelModal__Button-Arrow text-1'>
            <FontAwesomeIcon icon={faCaretRight}/>
          </button>
        </div>

        <div className="title text-center py-5">
          <Loader/>
        </div>
      </div>
    </div>
  )

  if(step === 1) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
        <header className='h-100'>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reservar espacio compartido</h2>
        </header>

        <div className='PanelModal__Date-Selector PanelModal__Confirm'>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('prev')}>
            <FontAwesomeIcon icon={faCaretLeft}/>
          </button>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
          <button className='PanelModal__Button-Arrow text-1' onClick={() => changeDate('next')}>
            <FontAwesomeIcon icon={faCaretRight}/>
          </button>
        </div>

        {currentDay?.isPastDay ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">No podés reservar una sala de reunión para una fecha en el pasado</span>
          </div>
        ) : currentDay?.isHoliday ? (
          <div className="PanelModal__Alert title text-center">
            <FontAwesomeIcon icon={faXmarkCircle}/>
            <span className="text-2 mt-2">Esta fecha está inhabilitada para realizar reservas</span>
          </div>
        ) : (
          <>
            <div className="PanelModal__Scroll-Content">
              {/* User Type */}
              <div className="PanelModal__Confirm">
                <h4 className='text-4 pb-3 text-start'>Reserva para:</h4>

                <div className="PanelModal__Options-Container">
                  {userTypes.length > 0 && userTypes.map((option, index) => {
                    if(option.value === reservation.user_type) return (
                      <button
                        className="PanelModal__Option--Active justify-content-start"
                        key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                      >
                        <div className="PanelModal__Option-Circle--Active"></div>
                        <span className='text-4'>
                          {option.text}<br/>
                        </span>
                      </button>
                    )
                    else return (
                      <button
                        className="PanelModal__Option justify-content-start"
                        key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                        onClick={() => handleReservationUserType(option.value)}
                      >
                        <div className="PanelModal__Option-Circle"></div>
                        <span className='text-4'>
                          {option.text}<br/>
                        </span>
                      </button>
                    )
                  })}
                </div>

                {reservation.user_type && (
                  <>
                    {reservation.user_type === 'admin-no-user' && (
                      <>
                        <div className="PanelModal__Inputs-Container mt-3">
                          <Input
                            label='Nombre del usuario'
                            name='first_name'
                            value={reservation.first_name}
                            onChange={handleTextInput}
                            icon={faUser}
                          />

                          <Input
                            label='Apellido del usuario'
                            name='last_name'
                            value={reservation.last_name}
                            onChange={handleTextInput}
                            icon={faUser}
                          />
                        </div>
                        <div className="mt-3">
                          <Input
                            label='Descripción'
                            name='description'
                            value={reservation.description}
                            onChange={handleTextInput}
                          />
                        </div>
                      </>
                    )}

                    {reservation.user_type === 'admin-user' && (
                      <>
                        <div className="mt-3">
                          <Input
                            label='Nombre del usuario'
                            name='user'
                            search={users}
                            value={reservation.first_name}
                            onChange={handleUserSelector}
                            icon={faUser}
                          />
                        </div>
                        <div className="mt-3">
                          <Input
                            label='Descripción'
                            name='description'
                            value={reservation.description}
                            onChange={handleTextInput}
                          />
                        </div>
                      </>
                    )}

                    {reservation.user_type === 'admin-vida' && (
                      <>
                        <div className="mt-3">
                          <Input
                            label='Referencia para la reserva especial'
                            name='reference'
                            placeholder='Ej: evento, reunión, etc'
                            value={reservation.reference}
                            onChange={handleTextInput}
                            icon={faUser}
                          />
                        </div>
                        <div className="mt-3">
                          <Input
                            label='Descripción'
                            name='description'
                            value={reservation.description}
                            onChange={handleTextInput}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              {/* Test Day */}
              {reservation.user_type && reservation.user_type !== 'admin-vida' && (
                <div className="PanelModal__Confirm">
                  <h4 className='text-4 mb-3 text-start'>¿Es un día de prueba?</h4>

                  <div className="PanelModal__Options-Container">
                    {testDayOptions.length > 0 && testDayOptions.map((option, index) => {
                      if(option.value === reservation.is_test_day) return (
                        <button
                          className="PanelModal__Option--Active justify-content-start"
                          key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                        >
                          <div className="PanelModal__Option-Circle--Active"></div>
                          <span className='text-4'>
                            {option.text}
                          </span>
                        </button>
                      )
                      else return (
                        <button
                          className="PanelModal__Option justify-content-start"
                          key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                          onClick={() => handleReservationTestDay(option.value)}
                        >
                          <div className="PanelModal__Option-Circle"></div>
                          <span className='text-4'>
                            {option.text}
                          </span>
                        </button>
                      )
                    })}
                  </div>
                </div>
              )}

              {/* Schedule */}
              <div className="PanelModal__Confirm PanelModal__Options-Container">
                {bookingTypes.length > 0 && bookingTypes.map((option, index) => {
                  if(option.value === 'Completo' && (!availability.isMorningAvailable || !availability.isAfternoonAvailable)) return (
                    <button
                      className="SpacesReservationModal__Option--Disabled m-0"
                      key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                      disabled
                    >
                      <div className="SpacesReservationModal__Option-Circle"></div>
                      <span className='text-4'>
                        {option.text}<br/>
                        {option.credits}
                      </span>
                    </button>
                  )
                  else if(option.value === 'Mañana' && !availability.isMorningAvailable) return (
                    <button
                      className="SpacesReservationModal__Option--Disabled m-0"
                      key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                      disabled
                    >
                      <div className="SpacesReservationModal__Option-Circle"></div>
                      <span className='text-4'>
                        {option.text}<br/>
                        {option.credits}
                      </span>
                    </button>
                  )
                  else if(option.value === 'Tarde' && !availability.isAfternoonAvailable) return (
                    <button
                      className="SpacesReservationModal__Option--Disabled m-0"
                      key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                      disabled
                    >
                      <div className="SpacesReservationModal__Option-Circle"></div>
                      <span className='text-4'>
                        {option.text}<br/>
                        {option.credits}
                      </span>
                    </button>
                  )
                  else if(option.value === reservation.booking_type) return (
                    <button
                      className="SpacesReservationModal__Option--Active m-0"
                      key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                    >
                      <div className="SpacesReservationModal__Option-Circle--Active"></div>
                      <span className='text-4'>
                        {option.text}<br/>
                        {option.credits}
                      </span>
                    </button>
                  )
                  else return (
                    <button
                      className="SpacesReservationModal__Option m-0"
                      key={`Spaces-Reservation-Modal-Option-${option.value}-${index}`}
                      onClick={() => handleReservationBookingType(option.value)}
                    >
                      <div className="SpacesReservationModal__Option-Circle"></div>
                      <span className='text-4'>
                        {option.text}<br/>
                        {option.credits}
                      </span>
                    </button>
                  )
                })}
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {reservation.user_type === 'admin-no-user' && (
                <>
                  {reservation.first_name && reservation.last_name && reservation.booking_type
                    ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
                    : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                  }
                </>
              )}

              {reservation.user_type === 'admin-user' && (
                <>
                  {reservation.user_id && reservation.booking_type
                    ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
                    : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                  }
                </>
              )}

              {reservation.user_type === 'admin-vida' && (
                <>
                  {reservation.reference && reservation.booking_type
                    ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
                    : <button className='button-disabled-panel my-3' disabled>Siguiente</button>
                  }
                </>
              )}

              {reservation.user_type === '' && (
                <button className='button-disabled-panel my-3' disabled>Siguiente</button>
              )}
            </div>

            <div className="mt-3">
              <Steps totalCount={3} currentCount={1} />
            </div>
          </>
        )}
      </div>
    </div>
  )

  if(step === 2) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card SpacesReservationModal__Card-Step-2" onClick={(e) => e.stopPropagation()}>
        <ModalHeader title={'Reservar espacio compartido'} closeModal={closeModal} goBack={() => { setStep(step - 1); setReservation({ ...reservation, chair_list: [], chair: '' }) }} />

        <div className='SpacesReservationModal__Content-Step-2'>
          <div>
            <h3 className='SpacesReservationModal__Subtitle text-3 my-4 pb-1'>Seleccioná un puesto</h3>

            <ChairsSelector
              chairsSelected={reservation.user_type === 'admin-vida' ? reservation.chair_list : reservation.chair}
              setChairsSelected={handleReservationChair}
              date={reservation.date}
              bookingType={reservation.booking_type}
            />
          </div>

          <div className="SpacesReservationModal__Image-Container pt-3">
            <img className="SpacesReservationModal__Image" src={DefaultImage} alt="Puestos"/>
          </div>
        </div>

        <div>
          <div className="d-flex justify-content-center">
            {reservation.chair_list.length > 0 || reservation.chair
              ? <button className='button-green-panel my-3' onClick={() => setStep(step + 1)}>Siguiente</button>
              : <button className='button-disabled-panel button-disabled my-3' disabled>Siguiente</button>
            }
          </div>

          <Steps totalCount={3} currentCount={2}/>
        </div>
      </div>
    </div>
  )

  if(step === 3) return (
    <div className="PanelModal px-3" onClick={closeModal}>
      <div className="PanelModal__Card" onClick={(e) => e.stopPropagation()}>
        <header>
          <button className='PanelModal__Button-GoBack' onClick={() => setStep(step - 1)}>
            <FontAwesomeIcon icon={faChevronLeft}/>
          </button>
          <button className='PanelModal__Button-Close' onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          <h2 className="PanelModal__Title text-1 text-center">Reserva tu lugar</h2>
        </header>

        <div className='PanelModal__Confirm'>
          <h3 className="mx-2 text-1">{ modalDate }</h3>
        </div>

        <div className="PanelModal__Confirm">
          {reservation.first_name && (
            <span>Usuario: <span className="fw-bold">{reservation.first_name} {reservation.last_name}</span></span>
          )}
          {reservation.reference && (
            <span>Referencia: <span className="fw-bold">{reservation.reference}</span></span>
          )}
        </div>

        <div className='PanelModal__Confirm'>
          {reservation.chair_list.length > 0
            ? <span>Puesto/s: <span className="fw-bold">
              N° { reservation.chair_list.map((item, index) => {
                if(index !== reservation.chair_list.length - 1) return `${item.number}, `
                else return `${item.number}`
              })}
            </span></span>
            : <span>Puesto: <span className="fw-bold">N° { reservation.chair.number}</span></span>
          }
        </div>

        <div className='PanelModal__Confirm'>
          <span>Horario: <span className="fw-bold">{ reservation.booking_type }</span></span><br />
          <span>{ bookingTypes.find(item => item.value == reservation.booking_type).schedule}</span>
        </div>

        <div className='PanelModal__Confirm'>
          <span>¿Es un día de prueba?: <span className="fw-bold">{ reservation.is_test_day ? 'Si' : 'No' }</span></span><br />
        </div>

        <div className="d-flex justify-content-center">
          <button className='button-green-panel my-3' onClick={createReservation}>Reservar</button>
        </div>

        <Steps totalCount={3} currentCount={3} />
      </div>
    </div>
  )

  if(step === 4) return (
    <FinishModal
      title='Reservar espacio compartido'
      message='¡Reserva exitosa!'
      closeModal={closeModal}
    />
  )

  if(step === 5) return (
    <ErrorModal
      title='Algo salió mal'
      message={reservationError}
      closeModal={closeModal}
      data={reservation}
      type='spaces'
    />
  )
}

SpacesAdminReservationModal.propTypes = {
  days: propTypes.array,
  initialData: propTypes.object,
  refreshData: propTypes.func,
  changeMonth: propTypes.func,
  closeModal: propTypes.func.isRequired
}

export default SpacesAdminReservationModal