import ReactDOM from 'react-dom/client'
import App from './router/Routes'
import AppContextProvider from './context/index.jsx'
import * as Sentry from '@sentry/react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/free-mode'
import './styles/index.sass'

Sentry.init({
  dsn: 'https://21697b3650fb0509858cbf7e47de2c2c@o4508303815999488.ingest.us.sentry.io/4508326476185600',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/www\.vidacowork\.com\//],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Sentry.ErrorBoundary>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </Sentry.ErrorBoundary>
)
